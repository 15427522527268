<template>
  <div>
    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded mb-6"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
          >
            <app-branch-select
              v-model="queryParams.branch_id"
              classes="small-input"
              hide-details
            />
          </v-col>

          <v-col class="text-end">
            <v-btn
              depressed
              small
              class="me-3"
              @click="$_print()"
            >
              <v-icon left>
                mdil-printer
              </v-icon>

              {{ $t('Print') }}
            </v-btn>

            <v-btn
              color="primary"
              depressed
              small
              @click="getData()"
            >
              <v-icon left>
                mdil-magnify
              </v-icon>

              {{ $t('Search') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded"
    >
      <v-card-text>
        <v-simple-table
          id="print-area"
          class="print-table"
          dense
        >
          <template #default>
            <thead>
              <tr>
                <invoice-header />
              </tr>

              <tr>
                <td
                  colspan="100"
                  class="text-center font-weight-bold d-none d-print-table-cell"
                >
                  {{ $t('Supplier Sum. Acc. Stmt.') }}
                </td>
              </tr>

              <tr>
                <th class="text-start">
                  {{ $t('#') }}
                </th>
                <th class="text-start">
                  {{ $t('Name') }}
                </th>
                <th class="text-start">
                  {{ $t('Phone') }}
                </th>
                <th class="text-center">
                  {{ $t('Credit') }}
                </th>
                <th class="text-center">
                  {{ $t('Debit') }}
                </th>
                <th class="text-center">
                  {{ $t('Balance') }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(data, index) in apiData.data"
                :key="index"
                :class="{
                  'striped-row': index % 2 == 1,
                }"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ data.name }}</td>
                <td>{{ data.phone }}</td>
                <td class="text-center">
                  $ {{ $_format_number(data.credit) }}
                </td>
                <td class="text-center">
                  $ {{ $_format_number(data.debit) }}
                </td>
                <td class="text-center">
                  $ {{ $_format_number(data.balance) }}
                </td>
              </tr>
              <tr
                v-if="apiData.totals"
                class="font-weight-bold"
              >
                <td colspan="3" />
                <td class="text-center">
                  $ {{ $_format_number(apiData.totals.sum_credit) }}
                </td>
                <td class="text-center">
                  $ {{ $_format_number(apiData.totals.sum_debit) }}
                </td>
                <td class="text-center">
                  $ {{ $_format_number(apiData.totals.balance) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      apiData: {},
      queryParams: {},
    }
  },

  mounted() {
    this.$_section_title({ title: 'Supplier Sum. Acc. Stmt.', icon: 'mdil-chart-bar' })

    setTimeout(() => {
      this.getData()
    }, 500)
  },

  methods: {
    getData() {
      axios.get(`supplier/summary-account-statement/`, { params: this.queryParams }).then(res => {
        this.apiData = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>